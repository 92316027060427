:root {
  font-family: "Inter", sans-serif;
}

@supports (font-variation-settings: normal) {
  :root {
    font-family: "InterVariable", sans-serif;
    font-optical-sizing: auto;
  }
}
@font-face {
  font-family: InterVariable;
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url("fonts/InterVariable.woff2") format("woff2");
}
@font-face {
  font-family: InterVariable;
  font-style: italic;
  font-weight: 100 900;
  font-display: swap;
  src: url("fonts/InterVariable-Italic.woff2") format("woff2");
}
@font-face {
  font-family: GeistMono;
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url("fonts/GeistMonoVF.woff2") format("woff2");
}
@font-face {
  font-family: DejaVuSansMono;
  font-style: normal;
  src: url("fonts/DejaVuSansMono.ttf") format("truetype");
}
@font-face {
  font-family: LibreCaslonCondensed;
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url("fonts/LibreCaslonCondensed.woff2") format("woff2");
}
@font-face {
  font-family: Texturina;
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url("fonts/Texturina.ttf") format("truetype");
}
@font-face {
  font-family: PoltawskiNowy;
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url("fonts/PoltawskiNowy.woff2") format("woff2");
}
body {
  font-family: var(--font-stack), InterVariable, -apple-system, BlinkMacSystemFont, "Helvetica Neue", Roboto, sans-serif;
  background-color: var(--color-background);
}

a {
  color: var(--color-primary);
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
  webkit-text-decoration-color: var(--color-accent);
  webkit-text-decoration-skip: true;
}

body,
h1,
h2,
p {
  color: var(--color-primary);
}

select {
  background-color: var(--color-background);
  color: var(--color-primary);
  border: 0.1em solid var(--color-primary);
  border-radius: 0.4em;
  padding: 0.4em 1em;
  margin: 0;
  width: 100%;
  font-family: inherit;
  font-size: inherit;
  cursor: inherit;
  line-height: inherit;
}

input {
  background-color: var(--color-background);
  color: var(--color-primary);
  border: 0.1em solid var(--color-primary);
  border-radius: 0.4em;
  padding: 0.4em 1em;
  margin: 0;
  height: 2em;
  font-family: inherit;
  font-size: inherit;
  cursor: inherit;
  line-height: inherit;
}

button {
  background-color: var(--color-primary);
  color: var(--color-background);
  border: 0.1em solid var(--color-accent);
  border-radius: 0.4em;
  padding: 0.8em 1.2em;
  margin: 0.5em 0;
  font-family: inherit;
  font-size: 0.9em;
  font-weight: 600;
  text-transform: uppercase;
  cursor: inherit;
  line-height: inherit;
  transition: 150ms ease-out 0ms;
  display: flex;
  justify-content: center;
  align-items: center;
}
button .startIcon,
button .endIcon {
  font-size: 1em;
  padding-top: 0.1em;
}
button .startIcon {
  margin-right: 0.4em;
}
button .endIcon {
  margin-left: 0.4em;
}
button:hover {
  filter: brightness(75%);
}

table {
  border: 1px solid var(--color-accent);
  border-radius: 0.4em;
  border-spacing: 0;
}
table td,
table th {
  border-bottom: 1px solid var(--color-accent);
  border-left: 1px solid var(--color-accent);
  padding: 0.4em 0.6em;
}
table td:first-child,
table th:first-child {
  border-left: none;
}
table tr:last-child > td {
  border-bottom: none;
}
table th {
  background-color: var(--color-accent);
  color: var(--color-background);
}

#mainContainer {
  margin: 0 20%;
}
@media only screen and (max-width: 900px) {
  #mainContainer {
    margin: 0 10%;
  }
}
@media only screen and (max-width: 400px) {
  #mainContainer {
    margin: 0 5%;
  }
}

#date {
  font-size: 1em;
  font-weight: 400;
  margin-bottom: 0px;
  text-transform: uppercase;
}

#greet {
  font-size: 3em;
  margin-top: 15px;
  font-weight: 700;
}

.halfWidth {
  width: 49%;
}

.fullWidth {
  width: 100%;
}

.halfWidthContainer {
  display: flex;
  flex-direction: row;
  align-items: start;
  justify-content: space-between;
}

.centerContentsVH {
  display: flex;
  align-items: center;
  justify-content: center;
}

.centerContentsV {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.optionSection {
  margin-bottom: 0.5em;
}
.optionSection .optionHeader {
  margin: 0;
  font-size: 1em;
  font-weight: 600;
  text-transform: uppercase;
}
.optionSection .button {
  width: 100%;
}

.applicationHeader,
.bookmarkHeader {
  font-size: 1.5em;
  font-weight: 800;
  text-transform: uppercase;
  margin-bottom: 0.3em;
}

.applicationName {
  font-size: 1em;
  margin-top: 0;
  font-weight: 500;
  text-transform: uppercase;
}

.applicationIcon {
  margin-top: 0;
  font-size: 1.2em;
  font-weight: 500;
  margin-right: 0.5em;
  padding-top: 0.2em;
}

.applicationLink {
  font-size: 0.8em;
  font-weight: 500;
  text-transform: uppercase;
  color: var(--color-accent) !important;
}

.icon {
  font-size: 2em;
}

.applicationContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  height: 2em;
  margin: 0em 0.2em 2em 0em;
  width: 15.2em;
}

.applicationText {
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1;
  overflow: hidden;
}
.applicationText span {
  font-size: 0.8em;
  text-transform: uppercase;
}

.applicationSection {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
}

.bookmarkHeader {
  margin-top: 2em;
}

.bookmarkSection {
  margin: 0em 0em 1.5em 0;
  width: 15.2em;
}
.bookmarkSection ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}
.bookmarkSection ul li {
  margin: 0.4em 0 0 0;
}

.bookmarkSectionHeader {
  font-size: 1.2em;
  font-weight: 600;
  text-transform: uppercase;
  color: var(--color-accent);
  margin: 0;
}

.bookmarkContainer {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
}

#modalToggleButton {
  bottom: 2vh;
  color: va(--color-accent);
  left: 2.4vw;
  position: fixed;
}

#modalToggleButtonIcon {
  font-size: 2.5em;
}

#modalBackground {
  position: fixed;
  left: 0px;
  top: 0px;
  -webkit-backdrop-filter: blur(5px) brightness(60%);
          backdrop-filter: blur(5px) brightness(60%);
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  overflow: scroll;
  justify-content: center;
  background-color: 0, 0, 0, 0.3;
}

#modal {
  background-color: var(--color-background);
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.3), 0 15px 12px rgba(0, 0, 0, 0.25);
  width: 50%;
  flex-direction: column;
  z-index: 999;
  border-radius: 1em;
}

#modalHeader {
  margin: 1em 2em 0em 2em;
  display: flex;
  justify-content: space-between;
  align-self: flex-start;
}
#modalHeader .icon {
  font-size: 1.5em;
  margin-top: 1em;
  margin-left: 0.5em;
}

#modalBody {
  padding: 0 2em;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  max-height: 70vh;
  overflow: scroll;
  margin: auto;
}
#modalBody.center {
  justify-content: center;
  align-items: center;
}
#modalBody .adminLoginHeader {
  margin: 0 0 0.3em 0;
}

#modalFooter {
  margin: auto;
  width: 100%;
  display: flex;
  font-size: 2em;
  justify-content: center;
  align-items: center;
  margin: 1em 0 0.5em 0;
}
#modalFooter .modalFooterContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}
#modalFooter .icon {
  font-size: 1em;
  margin-left: 0.5em;
}
#modalFooter .modalFooterText {
  margin: 0 0.3em 0 0.25em;
  font-size: 0.5em;
}

#themeButtonSection {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

#adminLoginScreen {
  min-height: 15em;
}

.modalSectionHeader {
  font-weight: 900;
  font-size: 1.4em;
  text-transform: uppercase;
  margin: 1em 0 0.2em 0;
}

.searchInput {
  background-color: transparent;
  border: 0;
  border-radius: 0px;
  border-bottom: thin solid var(--color-accent);
  color: var(--color-primary);
  font-size: 0.8em;
  height: 2em;
  transition: all 0.4s ease;
  width: 100%;
  padding: 0;
  margin-top: 2em;
}
.searchInput:focus {
  border-color: var(--color-primary);
  outline: none;
}

.themeButton {
  font-size: 0.8em;
  margin: 0.2em 0.5em;
  width: 10em;
  height: 3em;
  font-size: 0.7em;
  text-transform: uppercase;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.themeButton:hover {
  font-weight: bold;
}

/* Small Screens */
@media only screen and (max-width: 400px) {
  .applicationLink {
    display: none;
  }
}
.floatingLabelInputContainer {
  position: relative;
  display: flex;
  flex-direction: column;
}
.floatingLabelInputContainer label {
  position: absolute;
  pointer-events: none;
  transform: translate(0, 23px) scale(1);
  transform-origin: top left;
  transition: 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  font-size: 1em;
  line-height: 1;
  left: 1em;
}
.floatingLabelInputContainer .filled, .floatingLabelInputContainer:focus-within label {
  transform: translate(0, 12px) scale(0.8);
  color: var(--color-accent);
}
.floatingLabelInputContainer input {
  height: 2em;
  padding: 1.5em 1em 0.25em 1em;
  font-size: 1em;
  line-height: 1;
  transition: 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
}

.loadingBarContainer {
  height: 0.3em;
  border-radius: 0.4em;
  min-width: 10em;
  width: 100%;
  overflow: hidden;
}

.loadingBarBackground {
  height: 100%;
  border-radius: 0.4em;
  background-color: var(--color-primary);
  opacity: 0.2;
  min-width: 10em;
  width: 100%;
  position: relative;
  top: 0;
  left: 0;
}

.loadingBarValue {
  width: 100%;
  height: 100%;
  border-radius: 0.4em;
  background-color: var(--color-accent);
  animation: indeterminateAnimation 1s infinite ease-in-out;
  animation-direction: alternate;
  transform-origin: 0% 50%;
  position: relative;
  top: -0.3em;
  left: 0;
}

.statusMessage .result {
  font-size: 1.2em;
  font-weight: 600;
  color: var(--color-accent);
  margin: 0;
}
.statusMessage .message {
  margin-top: 0;
}

.statusMessageContainer {
  margin: 0.5em 0;
  min-width: 18.75em;
}

@keyframes indeterminateAnimation {
  0% {
    transform: translateX(-15%) scaleX(0.25);
  }
  100% {
    transform: translateX(90%) scaleX(0.55);
  }
}
.dropboxContainer {
  width: 100%;
  border: 0.1em solid var(--color-accent);
  border-radius: 0.4em;
  margin: 0.4em;
}
.dropboxContainer .dropboxHeader {
  font-size: 1.2em;
  padding: 0.4em 0.6em;
  font-weight: bold;
  color: var(--color-accent);
  position: relative;
}
.dropboxContainer .dropboxHeader::before {
  content: ""; /* Create a pseudo-element */
  position: absolute; /* Position the pseudo-element absolutely */
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: var(--color-background);
  filter: brightness(0.5);
  opacity: 0.4; /* Apply opacity to the pseudo-element */
  z-index: 0;
}
.dropboxContainer .dropboxHeader * {
  position: relative;
  z-index: 1;
}
.dropboxContainer .dropboxContent {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.5s ease-in-out;
}

.appInfo {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  border: 0.1em solid var(--color-accent);
  border-radius: 0.4em;
}
.appInfo .floatingLabelInputContainer {
  width: 30%;
  padding: 0.4em 0.2em;
}/*# sourceMappingURL=main.css.map */